<template>
  <div class="login-container">
    <div class="login-form-box">
      <div class="login-form">
        <div class="login-form-title">
          <h2>找回密码</h2>
          <span>您可以通过注册手机或者邮箱查收并输入正确的验证码完成密码重设</span>
        </div>
        <a-form
          :model="formState"
          :rules="rules"
          @finish="handleFinish"
          @finishFailed="handleFinishFailed"
        >
          <a-form-item name="phone">
            <a-input v-model:value="formState.phone" placeholder="手机号" class="ainput" />
          </a-form-item>
          <a-form-item name="yzm">
            <a-input v-model:value="formState.yzm" placeholder="验证码" class="ainput ainput_yzm db-mr10" />
            <a-button class="yzm" :disabled="isGetYzm" @click="getYZMFun">{{ Yzmtext === 60 ? '获取验证码' : Yzmtext }}</a-button>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              :disabled="formState.phone === '' || formState.yzm === ''"
              class="abutton"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="login-left-box" />
  </div>
</template>

<script>
// import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'

export default {
  name: 'Forget',
  data() {
    return {
      isGetYzm: false,
      Yzmtext: 60,
      formState: {
        phone: '',
        yzm: ''
      },
      rules: {
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        yzm: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      },
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }
  },
  methods: {
    // 获取验证码
    getYZMFun() {
      this.isGetYzm = true
      const setime = setInterval(() => {
        this.Yzmtext = --this.Yzmtext
        if (this.Yzmtext === 0) {
          this.isGetYzm = false
          clearInterval(setime)
          this.Yzmtext = 60
        }
      }, 1000)
    },
    handleFinish(values) {
      console.log(this.formState.phone)
    },
    handleFinishFailed(errors) {
      console.log(errors)
    }
  }
}
</script>
<style lang='less' scoped>
.login-container {
  width: 100%;
  height: 1080px;
  display: flex;

  .login-form-box {
    width: 1120px;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 95px;

    .login-form {
      width: 480px;
      height: 600px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

    }

    .login-form-title {

      & > img {
        width: 64px;
        height: 64px;
        margin-bottom: 40px;
      }
      & > h2 {
        color: @main-color;
        font-size: 36px;
        font-weight: bold;

      }
      & > span {
        display: block;
        color: @color;
        margin-bottom: 48px;
      }
    }
  }
  .ainput {
    width: 480px;
    height: 40px;
    border: 0;
    border-bottom: 1px solid #E5E5E5;
    outline: none !important;
    background: none !important;
    box-shadow: none;
    font-size: 18px;
    &:focus {
      border:none !important;
      border-bottom: 1px solid #E5E5E5 !important;
    }
  }

  .abutton {
    width: 480px;
    height: 56px;
    background: @bg-color;
    border: 1px solid #E5E5E5;
    border-radius: 33px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    margin-top: 48px;
  }
  .ainput_yzm {
    width: 310px;
    height: 40px;
  }
  .yzm {
    width: 160px;
    height: 40px;
    text-align: center;
    border: 1px solid #E5E5E5;
    border-radius: 20px;
  }

  .login-left-box {
    // width: 30%;
    height: 100%;
    color: @color;
    flex: 1;
    background: @bg-color;
  }
}
</style>
